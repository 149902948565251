import React from "react"
import {FormattedMessage, Link, useIntl} from "gatsby-plugin-intl"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Row, Col, Card, Divider, Button, Carousel} from "antd";
import logo from "../../assets/images/sp/sp-logo-9.svg"
import {DownloadOutlined, LinkOutlined} from "@ant-design/icons";
import kurz1 from "../../assets/images/sp/9/kurz-1.jpg";
import kurz2 from "../../assets/images/sp/9/kurz-2.jpg";
import kurz3 from "../../assets/images/sp/9/kurz-3.jpg";
import kurz4 from "../../assets/images/sp/9/kurz-4.jpg";

const SponsorPage = () => {
    const intl = useIntl()
    return (
        <Layout>
            <SEO
                lang={intl.locale}
                title={`Kurz | Sponsor | ${intl.formatMessage({ id: "title" })}`}
                keywords={["wuerth, wuerth open, open lugano, golf, wuerth golf open, golf lugano"]}
            />
            <div className="page-sponsor-detail">
                <div className="container text-center pt-4">
                    <h6 className="text-uppercase text-primary">Sponsor Würth Open 2021</h6>
                    <h4 className="text-uppercase fw-bold">Buca 9</h4>
                    <img src={logo} alt="Logo" className="sp-logo"/><br/>
                    <a href="https://kurz1948.ch/en" target="_blank">
                        <Button type="primary" icon={<LinkOutlined/>} className="my-3">Sito ufficiale</Button>
                    </a>
                </div>
                <section className="bg-secondary">
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 1}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Gioielleria</h6>
                                <h2>KURZ Lugano</h2>
                                <p>La gioielleria Kurz è lieta di accogliere i suoi clienti nella sua nuova boutique nel
                                    cuore di Lugano sotto i portici storici di Via Nassa 5 con una vasta gamma di
                                    orologi e gioielli esclusivi, tra cui naturalmente le collezioni di gioielli KURZ,
                                    apprezzate per la loro qualità e raffinatezza.<br/>
                                    L'azienda di gioielli con nove boutique nelle città e regioni di Zurigo, Basilea,
                                    Berna, Lucerna, Emmen, Ginevra e Lugano offre linee di gioielli di alta qualità in
                                    diamanti, oro, perle e gemme colorate, nonché un'esclusiva collezione di fedi
                                    nuziali KURZ. In tutte le sue boutique, KURZ combina il design contemporaneo e
                                    moderno dei gioielli con una consulenza orientata allo stile.<br/>
                                    Kurz è conosciuta per le sue offerte di diamanti e le sue competenze, che vanno dal
                                    contatto diretto con il tagliatore di diamanti alla progettazione e produzione di
                                    gioielli «single source». Il vantaggio speciale per i clienti è che anche i loro
                                    desideri individuali possono essere accolti e realizzati. Teniamo sempre presente le
                                    esigenze della donna sofisticata e moderna che apprezza i gioielli raffinati e la
                                    massima qualità.<br/>
                                    L’azienda lancia regolarmente bellissime collezioni esclusive KURZ. Traiamo
                                    ispirazione dalla moda, dallo stile di vita, dall'arte e dall'architettura e
                                    incorporiamo tendenze globali. È così che abbiamo creato pezzi di gioielleria ambiti
                                    come la Frangipani Collection, la nostra Eternity Collection, con diamanti in
                                    un'incastonatura unica, o la nostra Denim Collection, in cui sette diamanti si
                                    uniscono per formare un solitario brillante e potente.<br/>
                                    L'offerta di marchi esclusivi di gioielli KURZ a Lugano include le creazioni di
                                    alcune marche di gioielli di lusso rinomate a livello internazionale come Chopard e
                                    Pasquale Bruni.<br/>
                                    KURZ e il rivenditore ufficiale di oltre 22 marchi di orologi e di alcuni dei brand
                                    più famosi al mondo. I marchi disponibili
                                    da KURZ Lugano in ordine alfabetico sono i seguenti: Breitling, Chopard, Franck
                                    Muller, Glashütte Original, Longines, Rado e Tissot.
                                </p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 2}} className="text-justify">
                                <Carousel autoplay={3500} effect="fade">
                                    <div>
                                        <img src={kurz1} alt="KURZ"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kurz2} alt="KURZ"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <Row gutter={{xs: 16, lg: 48}} align="top">
                            <Col xs={{span: 24, order: 1}} lg={{span: 14, order: 2}} className="text-justify">
                                <h6 className="text-primary text-uppercase fw-bold">Gioielleria</h6>
                                <h2>I nostri servizi</h2>
                                <p>Il nostro personale competente, specializzato e multilingue ha una straordinaria
                                    esperienza nel settore e sarà lieto di assistervi, sia per una consulenza
                                    personalizzata, sia per una riparazione o qualsiasi servizio post-vendita.</p>
                                <h2>La nostra storia</h2>
                                <p>KURZ fu fondata a Zurigo nel 1948 dall'orafo Zurighese Armin Kurz. Fondatore e poi
                                    proprietario della gioielleria KURZ, Armin KURZ coltivava una grande passione per le
                                    tradizioni locali svizzere. Nel 1948 Armin Kurz inaugura il suo primo atelier a
                                    Zurigo. La sua visione è sempre stata quella di offrire gioielli di alta qualità ad
                                    un prezzo attraente ed un servizio eccellente da parte di uno staff altamente
                                    qualificato e competente. La visione lungimirante di Armin KURZ e la sua personale
                                    interpretazione del "lusso accessibile" si sono dimostrate nella filosofia aziendale
                                    di KURZ dal 1948 fino ad oggi. I gioielli e gli orologi offerti da KURZ non
                                    dovrebbero essere indossati solo per le occasioni speciali, ma ogni giorno e rendere
                                    così ogni giorno speciale. Da settembre 2020, l'azienda KURZ appartiene all'impresa
                                    familiare svizzera IGS AG.</p>
                            </Col>
                            <Col xs={{span: 24, order: 2}} lg={{span: 10, order: 1}} className="text-justify">
                                <Carousel autoplay={3500} effect="fade" className="mb-3">
                                    <div>
                                        <img src={kurz3} alt="KURZ"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                    <div>
                                        <img src={kurz4} alt="KURZ"
                                             className="sp-img d-inline-block align-top mb-4 rounded shadow"/>
                                    </div>
                                </Carousel>
                                <h6 className="text-primary text-uppercase fw-bold">La nostra boutique a Lugano</h6>
                                <Row gutter={{xs: 16, lg: 48}}>
                                    <Col>
                                        <p>
                                            Via Nassa 5, 6900 Lugano<br/>
                                            Tel.: +41 91 252 19 48<br/>
                                            Email: <a href="mailto:lugano@kurz1948.ch">lugano@kurz1948.ch</a><br/>
                                        </p>
                                    </Col>
                                    <Col>
                                        <p>
                                            Orari di apertura: <br/>
                                            LUN - VEN: 10.30 - 18.30 <br/>
                                            SAB: 10.00 - 18.00 <br/>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default SponsorPage
